<template>
  <div>
    <b-card :key="i" v-for="(group, i) in iconGroups" class="w-100">
      <b-row>
        <b-col
          v-for="(item, key) in iconData.slice(
            i * itemsPerRow,
            (i + 1) * itemsPerRow
          )"
          :key="key"
          class="text-center"
        >
          <b-avatar size="48" :variant="item.variant" class="mb-1 mt-1 mt-md-0">
            <feather-icon size="24" :icon="item.icon" />
          </b-avatar>
          <div>
            <h4 class="font-weight-bolder mb-0">
              {{ item.value }}
            </h4>
            <b-card-text class="font-medium-1 mb-0">
              {{ item.label }}
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "AnalyticsIcons",
  props: {
    iconData: {
      type: Array,
      required: true,
    },
    itemsPerRow: {
      type: Number,
      required: true,
    },
  },

  computed: {
    iconGroups() {
      return Array.from(
        Array(Math.ceil(this.iconData.length / this.itemsPerRow)).keys()
      );
    },
  },
};
</script>

<style lang="scss"></style>
