<template>
  <b-card>
    <b-card-header>
      <b-col>
        <b-row class="mb-1">
          <h4 class="card-title mb-50">{{ chartTitle }}</h4>
        </b-row>
        <b-row class="mb-1">
          <b-card-sub-title>Based on selected dates</b-card-sub-title>
        </b-row>
      </b-col>
      <b-button-group>
        <b-button
          variant="outline-primary"
          :class="{ active: selectedFilterBy === 'daily' }"
          @click.prevent="filterGraphBy('daily')"
        >
          Daily
        </b-button>
        <b-button
          variant="outline-primary"
          :class="{ active: selectedFilterBy === 'monthly' }"
          @click.prevent="filterGraphBy('monthly')"
        >
          Monthly
        </b-button>
      </b-button-group>
    </b-card-header>
    <b-card-body>
      <app-echart-line :key="key" :optionData="option" />
    </b-card-body>
  </b-card>
</template>

<script>
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue";

export default {
  name: "Chart",
  components: {
    AppEchartLine,
  },

  props: {
    selectedFilterBy: {
      type: String,
      required: true,
    },
    chartTitle: {
      type: String,
      default: "Statistics",
    },
    chartOptions: {
      type: Object,
      required: true,
    },
  },

  watch: {
    chartOptions(newChart, oldChart) {
      this.option = { ...newChart, grid: this.grid };
      this.key++;
    },
  },

  created() {
    this.option.grid = this.grid;
  },

  methods: {
    filterGraphBy(filter) {
      this.$emit("filterGraphBy", filter);
    },
  },

  data() {
    return {
      key: 0,
      grid: {
        width: "100%",
        left: "25px",
        top: "50px",
        show: false,
      },
      option: {
        legend: this.chartOptions.legend,
        xAxisData: this.chartOptions.xAxisData,
        series: this.chartOptions.series,
      },
    };
  },
};
</script>

<style lang="scss"></style>
